
<template>
  <div class="bgDarkNavy" style="min-height: 100vh;">
    <navbar class="ov ba z10">
      <div class="flexi pv15 m_ph15 ">
        <tbl_nm>
          <cl class="w20 l m_w33" style="max-width: 200px;">
            <router-link :to="{ name: 'Home' }"><img src="@/assets/imgs/logo.png" alt="logo" class="g_nm w100 m_w70 m_m_w100" style="max-width: 200px;"></router-link>
          </cl>
          <cl class="w60 c fs0 m_h nw">
            <div class="g_nm vm fs-1"><div class="navBarItem pv8 cb cap g_nm"><span class="buttonTextAnimation">{{ $t('services') }}</span></div></div>
            <div class="g_nm vm fs-1"><div class="navBarItem pv8 cb cap g_nm"><span class="buttonTextAnimation">{{ $t('work') }}</span></div></div>
            <div class="g_nm vm fs-1"><router-link :to="{ name: 'About' }"><div class="navBarItem pv8 cb cap g_nm"><span class="buttonTextAnimation">{{ $t('about') }}</span></div></router-link></div>
            <div class="g_nm vm fs-1"><div class="navBarItem pv8 cb cap g_nm"><span class="buttonTextAnimation">{{ $t('contact') }}</span></div></div>
            <div class="g_nm vm fs-1"><router-link :to="{ name: 'Report' }"><div class="navBarItem pv8 cb cap g_nm"><span class="buttonTextAnimation">{{ $t('report') }}</span></div></router-link></div>
          </cl>
          <cl class="w20 m_w66 r fs0 nw">
            <span class="vm fs15 cb upper">[{{ $i18n.locale }}]</span>
            <div class="ph8 g_nm vm">
              <img src="@/assets/imgs/themBtn.svg" alt="" style="width: 30px;">
            </div>
            <div class="h m_sib g_nm vm">
              <span class="vm fs-1_5 lh20 cb mdi mdi-menu" @click="openMenu()"></span>
            </div>
            <div class="g_nm m_h">
              <div class="g_nm vm fs0 pointer customBtn">
                <div class="g_nm vm customWihteBtn"><div class="oh"><span class="buttonTextAnimation">{{ $t('start_project') }}</span></div></div>
                <div class="g_nm l">
                  <div class="g_nm vm customArrowBtn"><span class="cc cb fs-1_5 mdi mdi-arrow-right mdiArrow"></span></div>
                </div>
              </div>
            </div>
          </cl>
        </tbl_nm>
      </div>
    </navbar>
    <div class="mobileMenuDiv z20 sh20 bgDarkNavy" style="opacity: 0.97;">
      <tbl_nm>
        <cl class="w50 l"><div class="cGray fs-1_5">{{ $t('menu') }}</div></cl>
        <cl class="w50 r">
          <div class="ph8 g_nm vm">
            <img src="@/assets/imgs/themBtn.svg" alt="" style="width: 25px;">
          </div>
          <div class="g_nm vm">
            <div class="round100 wbrdr pointer" style="padding:12px" @click="closeMenu()"><span class="cc mdi mdi-close fs15 cb"></span></div>
          </div>
        </cl>
      </tbl_nm>
      <div class="mt20 l">
        <div class="navMenuItem pv-1 cap fs-3 cb">{{ $t('services') }}</div>
        <div class="navMenuItem pv-1 cap fs-3 cb">{{ $t('work') }}</div>
        <div class="navMenuItem pv-1 cap fs-3 cb"><router-link :to="{ name: 'About' }"><div>{{ $t('about') }}</div></router-link></div>
        <div class="navMenuItem pv-1 cap fs-3 cb">{{ $t('contact') }}</div>
        <div class="navMenuItem pv-1 cap fs-3 cb"><router-link :to="{ name: 'Report' }"><div>{{ $t('report') }}</div></router-link></div>
      </div>
      <div class="mt20">
        <tbl_nm>
          <cl class="wa vb fs-2 l cGray">
            <div class="mt5">123456789</div>
            <div class="mt5">hello@puremines.com</div>
            <div class="mt5">Saudi, Riyadh</div>
          </cl>
          <cl class="colSep"></cl>
          <cl class="vb" style="width:3rem">
            <div class="socialMediaIcon mt-1"><span class="cc fs-2 mdi mdi-linkedin cw"></span></div>
            <div class="socialMediaIcon mt-1"><span class="cc fs-2 mdi mdi-twitter cw"></span></div>
            <div class="socialMediaIcon mt-1"><span class="cc fs-2 mdi mdi-instagram cw"></span></div>
            <div class="socialMediaIcon mt-1"><span class="cc fs-2 mdi mdi-facebook cw"></span></div>
            <div class="socialMediaIcon mt-1"><img src="@/assets/imgs/tiktok.png" alt="" class="cc" style="width: 1.5rem;"></div>
          </cl>
        </tbl_nm> 
      </div>
      
    </div>
    <router-view ></router-view>
    <footer class="mt100 c m_mt50">
      <div class="cov bgDarkNavy footer_bg " :style="{ backgroundImage: 'url(' + require('@/assets/imgs/footer_2_2.png') + ')' }" >
        <div class="mt30 pt30 pl150 md_pl100 l"><img src="@/assets/imgs/logo.png" alt="" class="w100" style="max-width: 14rem;"/></div>
        
      <div class="flexi  l w100 mt50 m_mt80 m_m_mt60 pb20 l">
        <tbl>
          <cl class="w50 vt">
            <img src="@/assets/imgs/googleR.png" alt="" class="mt-2_5 ld_mt0 w100" style="max-width: 11.5rem;">
            <p class="mt-2_5 cb fs-2_5 wspr">{{$t('our_work_text_2')}}</p>
            <div class="mt-1">
              <div class="g_nm vm fs0 pointer customBtn">
                <div class="g_nm vm customWihteBtn"><div class="oh"><span class="buttonTextAnimation">{{ $t('contact_us') }}</span></div></div>
                <div class="g_nm l" style="width:2.5rem;">
                  <div class="g_nm vm customArrowBtn"><span class="cc cb mdi mdi-arrow-right fs-1_5 mdiArrow"></span></div>
                </div>
              </div>
            </div>
            
          </cl>
          <cl class="colSep m_pv10"></cl>
          <cl class="w20 cap">
            <div class="learnDiv m_h">
              <div class="cb b fs-1">{{ $t('learn') }}</div>
              <div class="mt20 fs-1">
                <div class="footerItem mt5 m_mt8">{{ $t('about') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('culture') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('testimonials') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('processes') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('faqs') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('branding_faqs') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('new') }}</div>
                <div class="footerItem mt5 m_mt8">{{ $t('blog') }}</div>
              </div>
            </div>
            <tbl_nm class="h m_sb">
              <cl class="w33 vt">
                <div class="cb b fs-1">{{ $t('learn') }}</div>
                <div class="mt20 fs-1">
                  <div class="footerItem mt5 m_mt8">{{ $t('about') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('culture') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('testimonials') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('processes') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('faqs') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('branding_faqs') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('new') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('blog') }}</div>
                </div>
              </cl>
              <cl class="colSep"></cl>
              <cl class="w33 upper vt">
                <div class="cb b fs15 ">{{ $t('explore') }}</div>
                <div class="mt20 fs15">
                  <div class="footerItem mt5 m_mt8">{{ $t('home') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('work') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('new') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('services') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('careers') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('sectors') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('hex_text') }}</div>
                  <div class="footerItem mt5 m_mt8">{{ $t('contact') }}</div>
                </div>
              </cl>
              <cl class="colSep"></cl>
              <cl class="w33 vt">
                <div class="cb b fs15 ">get in touch</div>
                <div class="mt20 fs15">
                  <div class="footerItem mt5 m_mt8">123456789</div>
                  <div class="footerItem mt5 m_mt8">hello@puremines.com</div>
                  <div class="footerItem mt5 m_mt8">Saudi, Riyadh</div>
                </div>
              </cl>
            </tbl_nm>
          </cl>
          <cl class="colSep m_pv10 m_h"></cl>
          <cl class="w20 upper m_h">
            <div class="cb b fs-1 ">{{ $t('explore') }}</div>
            <div class="mt20 fs-1">
              <div class="footerItem mt5">{{ $t('home') }}</div>
              <div class="footerItem mt5">{{ $t('work') }}</div>
              <div class="footerItem mt5">{{ $t('new') }}</div>
              <div class="footerItem mt5">{{ $t('services') }}</div>
              <div class="footerItem mt5">{{ $t('careers') }}</div>
              <div class="footerItem mt5">{{ $t('sectors') }}</div>
              <div class="footerItem mt5">{{ $t('hex_text') }}</div>
              <div class="footerItem mt5">{{ $t('contact') }}</div>
            </div>
          </cl>
          <cl class="colSep m_pv10 m_h"></cl>
          <cl class="w10 cap vt m_h r">
            <div class="l cb b fs-1 ">get in touch</div>
            <div class="l mt20 fs-1">
              <div class="l footerItem mt5">123456789</div>
              <div class="l footerItem mt5">hello@puremines.com</div>
              <div class="l footerItem mt5">Saudi, Riyadh</div>
            </div>
          </cl>
        </tbl>
        <tbl class="mt20">
          <cl class="w50 vb">
            <tbl_nm>
              <cl class="vb" style="width:2.3rem">
                <div class="socialMediaIcon"><span class="cc fs-1 mdi mdi-linkedin cw"></span></div>
                <div class="socialMediaIcon"><span class="cc fs-1 mdi mdi-twitter cw"></span></div>
                <div class="socialMediaIcon"><span class="cc fs-1 mdi mdi-instagram cw"></span></div>
                <div class="socialMediaIcon"><span class="cc fs-1 mdi mdi-facebook cw"></span></div>
                <div class="socialMediaIcon"><img src="@/assets/imgs/tiktok.png" alt="" class="cc" style="width: 20px;"></div>
              </cl>
              <cl class="colSep"></cl>
              <cl class="wa vb cGray fs-1 cap">company reg number 123456789  |  all rights reserved  |  privacy poliy (you really care?)</cl>
            </tbl_nm>
          </cl>
          <cl class="colSep pv10"></cl>
          <cl class="w50 vb">
            
            <tbl>
              <cl class="ph5"><span class="wspr fs-1 cGray">We win <span class="u cMidBlue">awards</span> and <br> get recognized for</span></cl>
              <cl class="colSep pv10"></cl>
              <cl>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award1.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award2.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award3.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award4.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award5.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award6.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award7.png" alt=""  style="height: 2.5rem;"></div>
                <div class="g_nm vm p10"><img src="@/assets/imgs/awards/award8.png" alt=""  style="height: 2.5rem;"></div>
              </cl>
            </tbl>
          </cl>
        </tbl>
      </div>
    </div>
    </footer>
  </div>
</template>
<script>
  export default {
    name: "WebLayout",
    methods:{
      openMenu(){
        $('.mobileMenuDiv').addClass('active')
        $('body').addClass('oh')
      },
      closeMenu(){
        $('.mobileMenuDiv').removeClass('active')
        $('body').removeClass('oh');
      }
    }
  }
</script>