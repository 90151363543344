import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      services:'services',
      work:'work',
      about:'about',
      contact:'contact',
      report:'report',
      start_project:'Start a project',
      who_are_we:"who are we?",
      hi_we_are_puremind:"hi we are puremind",
      welcome_to:"Welcome to",
      pureminde:"PureMinds",
      header_text_2:", a leading media production company dedicated to creating captivating visual experiences.",
      about_puremind:"About Puremind",
      meet_the_team:"Meet the Team",
      meet_the_whole_team:"Meet the whole Team",
      our_experties:"Our Experties",
      expertiesText:"Strategies for advancing your business to new heights.",
      expertiesText_2:"Welcome to PureMinds, a leading media production company dedicated to creating captivating visual experiences.",
      view_all_services:"View all services",
      advertising_campaigns:'Advertising \n Campaigns',
      exhibition_event:'Exhibition \n & Event',
      film_production:'Film \n Production',
      event_entertainment:'Event \n & Entertainment',
      brand_identity:'Brand \n Identity',
      creative_solutions:'Creative \n Solutions',
      strategies_consulting:'Strategies &\n Consulting ',
      digital_marketing:'Digital \n marketing',
      view_more:"View More",
      our_work:"Our Work",
      seeWork:"See what we've been working on",
      view_details:"View \n details",
      our_work_text_2:"Do you feel ready? \n let's work together",
      contact_us:"Contact us",
      our_stats:"our stats",
      clients:"Clients",
      project:"Project",
      male_female:"Male:Female",
      project:"Project",
      shameful_plug_text_1:"our company collaboration with a diverse range of clients, including startups, global corporations, and government insitutions, porviding tailored solution that drive success and faster growth acreoss the region",
      shameful_plug_text_2:"we work with start-up businesses through to global organisation.",
      shameful_plug:"shameful plug",
      get_in_touch:"get in touch today",
      testimonials:"testimonials",
      testimonialsText:"client testimonials Real result, Real Feedback",
      read_more_reviews:"read more reviews",
      learn: "learn",
      about: "about",
      culture: "culture",
      testimonials: "testimonials",
      processes: "processes",
      faqs: "FAQs",
      branding_faqs: "branding FAQs",
      new: "new",
      blog:"blog",
      explore:"explore",
      home:"home ",
      work:"work ",
      new:"new ",
      services:"services ",
      careers:"careers ",
      sectors:"sectors ",
      hex_text:"hex text ",
      contact:"contact",
      accept_cookies:"accept cookies",
      menu:"Menu",
      our_report:"Our Report",
      saudi_arabia:"saudi arabia",
      Review_of_the_2024_budget_in_the_context:"Review of the 2024 budget in the context",
      Pureminds_budget_report_2024:"Pureminds budget report 2024",
      Review_of_the_2024_budget_in_the_context_of_recent_economic_developments:"Review of the 2024 budget in the context of recent economic developments.",
      Commercial_Registration:"Commercial Registration",
      VAT_Number:"VAT Number",
      Social_Insurance:"Social Insurance",
      Our_Amazing_Team:"Our Amazing Team",
      A_range_of_personalities_devoid:"A range of personalities, devoid",
      A_range_of_personalities_devoid_of_egos:"A range of personalities, devoid of egos",
      Are_you_our_next_squad_member:"Are you our next squad member?",
      See_Openings:"See Openings",
      Learn_about_Us:"Learn about Us",
      about_text:"We are an Award-Winning Lorem ipsum <span class='b u cb'>Advertising Campaigns, Exhibitions & Events</span> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad <span class='b u cb'>Branding</span> veniam, quis nostrud exercitation We are an Award-Winning Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad <br><br> We are an Award-Winning Lorem ipsum dolor sit amet, consectetur adipiscing elit, <span class='b u cb'>Film Production</span> tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
      Hear_from_our_Leader:"Hear from our Leader",
      We_remain_committed_to_pursuing_mutual_success:"We remain committed to pursuing mutual success.",
      about_text_2:"<span class='cb'>Since our journey began in 2011, we’ve combined a passion for creativity with the market’s urgent need for innovation in advertising.</span> <br> <br> This has resulted in fruitful partnerships with clients and successful <span class='b u cb'>collaborations</span> across diverse regions. Our projects have spanned <span class='b u cb'>creative campaigns</span>, conferences, and <span class='b u cb'>exhibitions</span>, each presenting <span class='b u cb'>new challenges</span> that <span class='b u cb'>inspire</span> us to learn and innovate.  We remain committed to pursuing mutual success, believing that <span class='b u cb'>creativity</span> is the language that unites us and the force that propels us forward. <span class='b u cb'>Our goal</span> remains steadfast: to leave a lasting impact on every project, offer innovative solutions, <span class='b u cb'>build trust</span> and <span class='b u cb'>collaboration</span>, and achieve <span class='b u cb'>shared success</span> with our clients. <br> <br>We take pride in our achievements and look forward to the future’s opportunities and challenges with excitement.",
      Awards:"Awards",
      We_are_recognized_and_awarded_for_the_excellence_of_our_achievements:"We are recognized and awarded for the excellence of our achievements",
      award_text:"Our company has proudly earned numerous international awards, showcasing our commitment to excellence in our work. These accolades reflect our dedication to quality and innovation in our industry.",
      about_us:"about us",
    },
    'ar': {
    },
  },
})
export default i18n
