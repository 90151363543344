import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import VueCookies from "vue-cookies";

// import VueAxios from "vue-axios";
// import axios from "axios";
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";

const app = createApp(App).use(store).use(router);
app.use(i18n);
app.use(VueCookies);
app.mount("#app");
